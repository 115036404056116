var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.games),function(game){return _c('div',{key:game.ID,staticClass:"py-2 tour-link"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-shrink-1 align-self-center text-left game-time-block"},[_c('div',{staticClass:"font-weight-normal time-start-game text-white"},[_vm._v(" "+_vm._s(game.time)+" ")]),_c('div',{staticClass:"small game-online",class:[
              game.state === 'live'
                ? _vm.dataSport.textClass
                : 'text-white-50 state-small'
            ]},[_vm._v(" "+_vm._s(game.state)+" ")])]),_c('div',{staticClass:"pl-2 w-100"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"text-right w-100"},[_c('div',{staticClass:"font-weight-normal team-font-size small text-white",domProps:{"textContent":_vm._s(
                  _vm.$i18n.locale === 'ru'
                    ? game.player_1_title_1x1
                    : game.player_1_title_eng_1x1
                )}})]),_c('div',{staticClass:"px-3 game-score text-center"},[(game.score_side1 > game.score_side2)?_c('div',[_c('div',{staticClass:"text-nowrap"},[_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))])])]):(game.score_side1 < game.score_side2)?_c('div',[_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side2))])])]):(
                  game.score_side1 === game.score_side2 &&
                    game.state !== 'upcoming'
                )?_c('div',[_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))])])]):_c('div',[_c('b',{staticClass:"separator text-white"},[_vm._v("-")])])]),_c('div',{staticClass:"text-left w-100"},[_c('div',{staticClass:"font-weight-normal team-font-size small text-white",domProps:{"textContent":_vm._s(
                  _vm.$i18n.locale === 'ru'
                    ? game.player_2_title_1x1
                    : game.player_2_title_eng_1x1
                )}})])])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }